@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Poppins&family=Roboto:wght@100;300;400;500;700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400&family=Poppins&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&family=Poppins&display=swap'); */

html, body>div {
    font-family: 'Poppins', 'Noto Sans JP', Arial,Helvetica,sans-serif;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* body * {
    font-family: "Noto Sans JP";
} */

.mainWrapper {
    margin: 0 0 0 auto;
    top: 48px;
    /* height: calc(100vh - 48px); */
    /* height: 100%; */
    /* width: 100%; */
    width: calc(100% - 64px);
    position: relative;
}

*::-webkit-scrollbar {
    height: 10px;
    width:  10px;
}

*::-webkit-scrollbar-track {
    background-color: #eee;
}

*::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #bdbdbd;
    border-radius: 6px;
}

*::-webkit-scrollbar-coener {
    background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#loadingSlack_container__kvfIc {
  margin: 140px auto;
  height: 50px;
  width: 50px;
  -webkit-transform: rotate(-16deg);
          transform: rotate(-16deg);
}

.loadingSlack_strand_h__RKViM {
  height: 10px;
  width: 50px;
  border-radius: 10px;
  opacity: 0.8;
}

#loadingSlack_blue__2rjDA {
  margin-top: 8px;
  background-color: #6dcadc;
  -webkit-animation: loadingSlack_blue__2rjDA 2s infinite;
          animation: loadingSlack_blue__2rjDA 2s infinite;
}

#loadingSlack_pink__wTzqV {
  margin-top: 9px;
  background-color: #e01463;
  -webkit-animation: loadingSlack_pink__wTzqV 2s infinite;
          animation: loadingSlack_pink__wTzqV 2s infinite;
}

@-webkit-keyframes loadingSlack_blue__2rjDA {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    width: 10px;
  }
  35% {
    width: 50px;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  65%{
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
    width: 10px;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    width: 10px;
  } 
}

@keyframes loadingSlack_blue__2rjDA {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    width: 10px;
  }
  35% {
    width: 50px;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  65%{
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
    width: 10px;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    width: 10px;
  } 
}

@-webkit-keyframes loadingSlack_pink__wTzqV {
  0% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
    width: 10px;
  }
  35% {
    width: 50px;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  65%{
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    width: 10px;
  }
  100% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
    width: 10px;
  }
}

@keyframes loadingSlack_pink__wTzqV {
  0% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
    width: 10px;
  }
  35% {
    width: 50px;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  65%{
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    width: 10px;
  }
  100% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
    width: 10px;
  }
}

#loadingSlack_v__2VnIl {
  position: relative;
  top: -40px;
  right: 3.5px;
}

.loadingSlack_strand_v__y0qVU {
  height: 50px;
  width: 10px;
  border-radius: 10px;
  opacity: 0.8;
}

#loadingSlack_green__3h9eS {
  margin-left: 9px;
  display: inline-block;
  background-color: #3db991;
  -webkit-animation: loadingSlack_green__3h9eS 2s infinite;
          animation: loadingSlack_green__3h9eS 2s infinite;
}

#loadingSlack_yellow__27gGT {
  display: inline-block;
  margin-left: 8px;
  background-color: #e9a81f;
  -webkit-animation: loadingSlack_yellow__27gGT 2s infinite;
          animation: loadingSlack_yellow__27gGT 2s infinite;
}

@-webkit-keyframes loadingSlack_green__3h9eS {
  0% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    height: 10px;
  }
  35% {
    height: 50px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  65%{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    height: 10px;
  }
  100% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    height: 10px;
  } 
}

@keyframes loadingSlack_green__3h9eS {
  0% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    height: 10px;
  }
  35% {
    height: 50px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  65%{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    height: 10px;
  }
  100% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    height: 10px;
  } 
}

@-webkit-keyframes loadingSlack_yellow__27gGT {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    height: 10px;
  }
  35% {
    height: 50px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  65%{
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    height: 10px;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    height: 10px;
  } 
}

@keyframes loadingSlack_yellow__27gGT {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    height: 10px;
  }
  35% {
    height: 50px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  65%{
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    height: 10px;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    height: 10px;
  } 
}

