@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Poppins&family=Roboto:wght@100;300;400;500;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400&family=Poppins&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&family=Poppins&display=swap'); */

html, body>div {
    font-family: 'Poppins', 'Noto Sans JP', Arial,Helvetica,sans-serif;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* body * {
    font-family: "Noto Sans JP";
} */

.mainWrapper {
    margin: 0 0 0 auto;
    top: 48px;
    /* height: calc(100vh - 48px); */
    /* height: 100%; */
    /* width: 100%; */
    width: calc(100% - 64px);
    position: relative;
}

*::-webkit-scrollbar {
    height: 10px;
    width:  10px;
}

*::-webkit-scrollbar-track {
    background-color: #eee;
}

*::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #bdbdbd;
    border-radius: 6px;
}

*::-webkit-scrollbar-coener {
    background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
