#container {
  margin: 140px auto;
  height: 50px;
  width: 50px;
  transform: rotate(-16deg);
}

.strand_h {
  height: 10px;
  width: 50px;
  border-radius: 10px;
  opacity: 0.8;
}

#blue {
  margin-top: 8px;
  background-color: #6dcadc;
  animation: blue 2s infinite;
}

#pink {
  margin-top: 9px;
  background-color: #e01463;
  animation: pink 2s infinite;
}

@keyframes blue {
  0% {
    transform: translateX(0);
    width: 10px;
  }
  35% {
    width: 50px;
    transform: translateX(0);
  }
  65%{
    transform: translateX(40px);
    width: 10px;
  }
  100% {
    transform: translateX(0);
    width: 10px;
  } 
}

@keyframes pink {
  0% {
    transform: translateX(40px);
    width: 10px;
  }
  35% {
    width: 50px;
    transform: translateX(0);
  }
  65%{
    transform: translateX(0px);
    width: 10px;
  }
  100% {
    transform: translateX(40px);
    width: 10px;
  }
}

#v {
  position: relative;
  top: -40px;
  right: 3.5px;
}

.strand_v {
  height: 50px;
  width: 10px;
  border-radius: 10px;
  opacity: 0.8;
}

#green {
  margin-left: 9px;
  display: inline-block;
  background-color: #3db991;
  animation: green 2s infinite;
}

#yellow {
  display: inline-block;
  margin-left: 8px;
  background-color: #e9a81f;
  animation: yellow 2s infinite;
}

@keyframes green {
  0% {
    transform: translateY(40px);
    height: 10px;
  }
  35% {
    height: 50px;
    transform: translateY(0);
  }
  65%{
    transform: translateY(0);
    height: 10px;
  }
  100% {
    transform: translateY(40px);
    height: 10px;
  } 
}

@keyframes yellow {
  0% {
    transform: translateY(0);
    height: 10px;
  }
  35% {
    height: 50px;
    transform: translateY(0);
  }
  65%{
    transform: translateY(40px);
    height: 10px;
  }
  100% {
    transform: translateY(0px);
    height: 10px;
  } 
}
